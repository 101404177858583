.post-wrapper {
    display: flex;
    gap: 1rem;
    color: rgb(87, 87, 87);
    min-width: 330px;
}

.hdng {
    font-size: 18px !important;
    margin-bottom: 0.25rem;
    margin-top: 0.15rem;
    line-height: 1.6;
}

.content {
    font-size: 15px;
    line-height: 1.7;
    margin-top: 2rem;
}

.img {
    width: 50%;
    height: 200px;
    object-fit: cover;
    margin-right: 0.75rem;
}

.post-info {
    display: flex;
    justify-content: space-between;
    color: var(--primary-color);
}

.post-info span {
    font-size: 13px;
    margin-top: 1rem;
}

@media (max-width: 700px) {
    .hdng {
        font-size: 15px;
        margin-bottom: 0.75rem;
    }

    .post-info span {
        font-size: 11px;
    }

    .img {
        height: 130px;
    }

    .hdng {
        font-size: 14px !important;
    }

    .content {
        font-size: 13px;
        margin-top: 0rem;
    }
}
