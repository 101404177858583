.modal-wrapper {
    position: absolute;
    z-index: 5;
    top: 55px;
    left: 500px;
    padding: 0.75rem;
    width: 500px;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    animation: animate 0.15s ease-out;
}

@keyframes animate {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@media (max-width: 700px) {
    .modal-wrapper {
        font-size: 12px;
        position: absolute;
        top: 53px;
        left: 50%;
        transform: translateX(-50%);
        width: 250px;
    }
}
